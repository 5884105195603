.popup {
    &.active{
      display: flex;
    }
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10;
    display: none;
    .popup-holder {
      background: url("../../assets/images/blue-bg.png") no-repeat rgba(0, 1, 35, 0.9);
      border-radius: 24px;
      max-width: 560px;
      margin: 0 auto;
      text-align: center;
      padding: 48px 40px;
      background-size: cover;
      h2{
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        margin: 0 0 12px;
      }
      p{
        font-weight: 300;
        margin: 0 0 32px;
      }
      .row{
        margin: 0 0 35px;
      }
      label{
        display: block;
        text-align: initial;
        margin: 0 0 16px;
      }
      input{
        width: 100%;
      }
      .btn{
        padding: 16px 30px;
        margin: 0 auto;
      }
      .cross-icon{
        position: absolute;
        top: 20px;
        right: 20px;
      }
      .success-text-color{
        color: green;
      }
      .error-text-color{
        color: red;
      }
    }
  }