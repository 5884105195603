@import "./abstracts/extend.scss";
@import "./theme.scss";

@font-face {
    font-family: 'Circular Std';
    src: url('../font/CircularStd-Bold.woff2') format('woff2'),
        url('../font/CircularStd-Bold.woff') format('woff'),
        url('../font/CircularStd-Bold.ttf') format('truetype');
    @include font_style_display(bold, normal, swap);
}
@font-face {
    font-family: 'Circular Std';
    src: url('../font/CircularSpotifyText-Light.woff2') format('woff2'),
        url('../font/CircularSpotifyText-Light.woff') format('woff'),
        url('../font/CircularSpotifyText-Light.ttf') format('truetype');
    @include font_style_display(300, normal, swap);
}
@font-face {
    font-family: 'Circular Std';
    src: url('../font/CircularStd-Medium.woff2') format('woff2'),
        url('../font/CircularStd-Medium.woff') format('woff'),
        url('../font/CircularStd-Medium.ttf') format('truetype');
    @include font_style_display(500, normal, swap);
}
@font-face {
    font-family: 'Circular Std Book';
    src: url('../font/CircularStd-Book.woff2') format('woff2'),
        url('../font/CircularStd-Book.woff') format('woff'),
        url('../font/CircularStd-Book.ttf') format('truetype');
    @include font_style_display(normal, normal, swap);
}
* {
    @extend %border_box;
    &:before, 
    &:after {
        @extend %border_box;
    }
}
html {
    scroll-behavior: smooth;
}
body {
    color: var(--body_text_color);
    font: 300 16px/1.2 var(--font_family_circular_std);
    min-width: 320px;
    background-color: var(--body_bg_color);
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: none;
    background-repeat: no-repeat;
    background-size: 100%;
    @extend %margin_0;
    @include breakpoint(desktop, min) {
        font-size: 18px;
    }
    @include breakpoint(desktop, max){
        background-image: url('./../images/mobile-body-bg-mask.png');
    }
}
.webp {
    body {
        @include breakpoint(desktop, min){
            @include background_next_gen_img(body-bg-mask, webp, true);
        }
    }
}
.no-webp {
    body {
        @include breakpoint(desktop, min){
            @include background_next_gen_img(body-bg-mask, webp, false);
        }
    }
}
img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 0/0;
}
.w-auto{
    width: max-content;
}
.required-field {
    color: #00FFFF;
    font-style: normal;
    margin-left: 5px;
    font-size: 17px;
}
a, button {
    --anchor_button_size: 16px;
    font-weight: 300;
    font-size: var(--anchor_button_size);
    text-decoration: none;
    color: var(--anchor_color);
    outline: none;
    display: inline-block;
    @include transition(opacity, 0.4s, ease, 0s);
    &:hover {
        color: var(--anchor_hover_color);
    }
    @include breakpoint(desktop, min) {
        --anchor_button_size: 18px;
    }
}
p {
    @extend %margin_0;
}
:where(picture, img, video) {
    @include display(block);
}
figure {
    @extend %margin_0;
}
button {
    border: 0;
    background: transparent;
    cursor: pointer;
    padding: 0
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4 {
    font-family: var(--font_family_circular_std);
    font-weight: 500;
    line-height: 1.4;
}
h1, .h1 {
    font-size: 37px;
    margin: 0 0 30px;
    @include breakpoint(tablet, min) {
        font-size: 48px;
        margin: 0 0 27px;
    }
}
h2, .h2 {
    font-size: 40px;
    margin: 0 0 24px;
    @include breakpoint(tablet, min) {
        font-size: 40px;
        margin: 0 0 32px;
    }
}
h3, .h3 {
    font-size: 24px;
}
h4, .h4 {
    font-size: 19px;
}
ul, 
ol {
    @extend %margin_0;
    @extend %padding_0;
    list-style: none;
}
input, textarea, select {
    vertical-align: middle;
    color: #000;
    &[type=text], 
    &[type=search], 
    &[type=email], 
    &[type=password], 
    &[type=file], 
    &[type=submit], 
    &[type=tel], 
    textarea {
        -webkit-appearance: none;
        border-radius: 0;
        -webkit-border-radius: 0;
    }
}
input[type="text"], 
input[type="email"], 
input[type="tel"], 
input[type="password"], 
input[type="search"], 
textarea {
    background: rgba(255, 255, 255, 0.08);
    border: 1.5px solid rgba(255, 255, 255, 0.12);
    border-radius: 10px;
    margin: 0;
    height: 60px;
    padding-inline: 20px;
    color: #fff;
    outline: none;
    font: 18px/22px 'NeueHaasUnicaPro-Regular', Arial, Helvetica, sans-serif;
}
input[type="radio"] {
    --radio_btn_width_height: 15px;
    appearance: none;
    background: linear-gradient(to bottom, var(--radio_btn_bg_color));
    margin: 0;
    color: currentColor;
    width: var(--radio_btn_width_height);
    height: var(--radio_btn_width_height);
    border-radius: 50%;
    display: grid;
    place-content: center;
    &:before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        background: var(--radio_btn_checked);
        margin: -2px 0 0;
    }
    &:checked {
        &:before {
            transform: scale(1);
        }
    }
}
textarea {
    resize: none;
    padding: 5px 19px;
    height: 113px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: #fff !important;
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
}
.btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px 10px;
    @include breakpoint(tablet, min) {
        margin: 0 0 0 10px;
    }
}
.btn {
    --btn_font_size: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.6;
    padding: 10px 30px;
    border-radius: 8px;
    color: var(--btn_color);
    @include breakpoint(tablet, min) {
        padding: 10px 20px;
    }
    @include breakpoint(desktop, min) {
        padding: 15px 30px;
    }
    cursor: pointer;
    &.btn-outline-primary {
        background-color: transparent;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            inset: 0;
            border-radius: 8px;
            padding: 2px;
            background: linear-gradient(180deg, #00FFFF, #2E3192);
            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
        }
    }
    &.btn-primary {
        background: linear-gradient(to bottom, #00FFFF, #2E3192);
    }
    &:hover {
        color: #fff;
    }
}
.container {
    max-width: 1170px;
    padding: 0 15px;
    margin: 0 auto;
    &:after {
        content: '';
        @include display(block);
        clear: both;
    }
}
.relative{
    position: relative;
}
.absolute{
    position: absolute;
}
#wrapper {
    overflow: hidden;
    width: 100%;
    position: relative;
}
.hidden {
    display: none;
}
.flex{
    display: flex;
}
.items-center{
    align-items: center;
}
.flex-text-center{
    justify-content: center;
}
.ml-auto{
    margin-left: auto;
}
.inline-block {
    display: inline-block;
}
.d-block {
    display: block;
}
::placeholder {
    color:var(--placeholder-color) !important;
    opacity: 1;
}

:-ms-input-placeholder {
    color:var(--placeholder-color) !important;
}

::-ms-input-placeholder {
    color:var(--placeholder-color) !important;
}
.none{
    #header, #footer, .newsletter {
        @extend .hidden;
    }
}