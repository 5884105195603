.login-area{
  .right-col{
    max-width: 600px;
    .row{
      input{
        width: 100%;
      }
      &.fields{
        gap: 20px;
      }
      .col{
        flex: 1;
      }
    }
    .accept-row{
      .fancy-checkbox{
        font-size: 16px;
      }
      label{
        margin: 0;
      }
    }
    .right-content{
      display: flex;
      flex-direction: column;
      height: calc(100% - 50px);
      justify-content: center;
    }
  }
  .icon-eye{
    position: absolute;
    bottom: 20px;
    right: 15px;
    cursor: pointer;
    img{
      width: auto;
    }
  }
  .success-text-color{
    color: green;
  }
  .error-text-color{
    color: red;
  }
  .select-country{
    background: rgba(255, 255, 255, 0.08);
    border: 1.5px solid rgba(255, 255, 255, 0.12);
    border-radius: 10px;
    margin: 0;
    height: 59px;
    color: #6d5858;
    width: 299px;
    padding: 14px;
    font-size: 16px;
  }
}

@media (max-width: 1610px) {
  .login-area{
    .right-col{
      .right-content{
        &.signUp{
          display: inherit;
          padding-inline: 1px;
          -ms-overflow-style: none;
          scrollbar-width: none;
          overflow-y: scroll;
          padding-top: 40px;
        }
        &::-webkit-scrollbar{
          display: none;
        }
      }
    }
  }
}