@mixin font_style_display($font-weight, $font-style, $font-display) {
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: $font-display;
}
@mixin background_next_gen_img($image-name, $image-formate, $webp) {
	@if $webp {
		background-image: url('./../images/#{$image-name}.#{$image-formate}');
	}
	@else {
		background-image: url('./../images/#{$image-name}.#{$image-formate}');
	}
}
$text_classes: (
  center: center,
  left: left
);
@each $key, $val in $text_classes{
  .text-#{$key} {
    text-align: #{$val};
  }
}
@mixin transition($for, $time-in, $type, $time-out) {
  transition: $for $time-in $type $time-out;
}
@mixin transform($property) {
  transform: $property;
}
@mixin display($display, $vertical_align:null) {
  display: $display;
  vertical-align: $vertical_align;
}