.modal {
    &.modal-overlay {
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 1072;
        opacity: 1;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
    }
    .modal-lg {
        transform: translate(0,0);
        transition: transform .3s ease-out,-webkit-transform .3s ease-out;
        max-width: 945px;
        margin: 1.75rem auto;
        border-radius: 30px;
        overflow: hidden;
        .modal-hold {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .image-hold {
            picture, img {
                height: 100%;
            }
        }
        .text-hold {
            background: url("../../assets/images/modal-box-grains.png") no-repeat var(--body_bg_color);
            background-size: cover;
            background-attachment: fixed;
            line-height: 1.6;
            padding: 60px 30px 45px;
            h2 {
                font-size: 32px;
            }
            .land-list {
                padding: 40px 0;
                li {
                    margin: 8px 0;
                    position: relative;
                    padding: 0 0 0 20px;
                    &:first-child {
                        margin-top: 0;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:before {
                        --counters_width_height: 10px;
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        width: var(--counters_width_height);
                        height: var(--counters_width_height);
                        border-radius: 50%;
                        background: linear-gradient(to bottom, var(--radio_btn_bg_color));
                        transform: translateY(-50%);
                    }
                }
            }
        }
        .cross-icon {
            display: inline-block;
            position: absolute;
            right: 35px;
            top: 25px;
            button {
                background: transparent;
                padding: 0;
                cursor: pointer;
            }
        }
    }
}