@import "./../../../assets//scss//abstracts/mixins/media-quries.scss";
.leadership {
    padding: 45px 0;
    .heading-hold {
        max-width: 780px;
        margin: 0 auto 40px;
        line-height: 1.6;
    }
    .card {
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(250px, 1fr));
        gap: 65px 20px;
        margin: 0 0 40px;
        padding: 40px 0;
        @include breakpoint(widescreen, min) {
            grid-template-columns: repeat(4, 270px);
        }
        .card-hold {
            .card-image {
                overflow: hidden;
                margin: 0 0 30px;
                border-radius: 14px;
            }
            .card-text {
                font-size: 15px;
                line-height: 1.6;
                h3 {
                    margin: 0 0 8px;
                }
                p {
                    margin: 0 0 16px;
                }
                .card-icon {
                    display: flex;
                    align-items: center;
                    .icon-hold {
                        --right_arrow_width_height: 18px;
                        width: var(--right_arrow_width_height);
                        height: var(--right_arrow_width_height);
                        margin: 0 10px 0 0;
                    }
                }
            }
        }
    }
}