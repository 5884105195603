@import './../variables/reset-variables.scss';
@mixin breakpoint($breakpoint, $direction) {
    @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);
        @if $direction == max {
            @media (max-width: ($breakpoint-value - 0.02)) {
                @content;
            }
        }
        @else if $direction == min {      
            @media (min-width: $breakpoint-value) {
                @content;
            }
        }
        @else {
            @media ($direction: $breakpoint-value) {
              @content
            }
        }

    }
    
    @else {
        @if $direction == max {
            @media (max-width: $breakpoint) {
                @content;
            }
        }
        @else if $direction == min {      
            @media (min-width: $breakpoint) {
                @content;
            }
        }
        @else {
            @media ($direction: $breakpoint) {
              @content
            }
        }

    }
}