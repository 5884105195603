@import "./../../assets/scss/abstracts/mixins/media-quries.scss";
.hero-banner {
    padding: 45px 0;
    @include breakpoint(tablet, max) {
        padding: 0;
        margin: 0;
    }
    &-hold {
        max-width: 950px;
        margin: 0 auto;
        .video-hold {
            margin: 55px 0 25px;
            border-radius: 24px;
            overflow: hidden;
            @include breakpoint(tablet, min) {
              margin: 70px 0;
            }
        }
    }
}