.our-mission {
    padding: 45px 0;
    .heading-hold {
        max-width: 754px;
        margin: 0 auto 40px;
        line-height: 1.6;
        h2 {
            font-size: 32px;
        }
    }
}