
// h1 {
//   font-size: 37px;
//   margin: 0 0 27px;
//   color: white;
// }
.ask-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background: rgba(255, 255, 255, 0.08);
  border: 1.5px solid rgba(255, 255, 255, 0.12);
  border-radius: 14px;
  color: white;
  p {
    margin: 0;
  }
}
.contact-info {
  display: flex;
  justify-content: space-between;
  margin-block: 20px;
  gap: 20px;
}
.info-box {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 24px 44px;
  background: rgba(255, 255, 255, 0.08);
  border: 1.5px solid rgba(255, 255, 255, 0.12);
  border-radius: 14px;
  gap: 10px;
  flex-basis: 33.33%;
  b {
    font-weight: 500;
    color: white;
  }
  a {
    font-weight: 300;
    font-size: 16px;
    text-decoration: none;
    color: white;
  }
}
.enquiry-text {
  color: white;
  padding-block: 40px;
}
.form-fields {
  display: flex;
  gap: 40px;
}
.fields {
  flex-basis: 50%;
  margin-bottom: 20px;
  label {
    margin-bottom: 10px;
    display: block;
  }
  input {
    width: 100%;
    margin-top: 10px;
    padding-inline: 20px;
  }
  textarea {
    width: 100%;
    margin-top: 10px;
    padding-top: 10px;
  }
}
.send-btn{
  display: block;
  margin-left: auto;
  margin-top: 60px;
}