.login-area{
  height: 100vh;
  align-items: center;
  .left-col{
    height: 100%;
    width: 45%;
    padding-top: 34px;
    background: rgba(255, 255, 255, 0.08);
    border: 1.5px solid rgba(255, 255, 255, 0.12);
    &:before{
      position: absolute;
      content: "";
      background: url("../../assets/images/shadow.png") no-repeat;
      background-size: 370px;
      width: 370px;
      height: 370px;
      opacity: 0.06;
      top: -110px;
      left: -120px;
    }
    img{
      width: auto;
    }
    .main-content{
      height: calc(100% - 50px);
      max-width: 350px;
      margin: 0 auto;
    }
    .inner-content{
      flex-direction: column;
      justify-content: center;
      height: 100%;
      h1{
        font-size: 40px;
        line-height: 51px;
        margin: 0 0 16px;
      }
      p{
        font-weight: 300;
        font-size: 18px;
        line-height: 28px;
        margin: 0;
      }
    }
  }
  .right-col{
    padding-top: 34px;
    width: 55%;
    height: 100%;
    max-width: 560px;
    margin: 0 auto;
    h2{
      font-size: 40px;
      line-height: 51px;
      margin: 0 0 8px;
    }
    p{
      margin: 0 0 48px;
    }
    .row{
      margin: 0 0 24px;
      label{
        font-size: 18px;
        margin: 0 0 18px;
        display: block;
        font-weight: 400;
      }
      input{
        background: rgba(255, 255, 255, 0.08);
        border: 1.5px solid rgba(255, 255, 255, 0.12);
        border-radius: 10px;
        margin: 0;
        height: 60px;
      }
      a{
        color: #00FFFF;
      }
    }
    .accept-row{
      label{
        margin: 0;
      }
      .fancy-checkbox{
        padding: 1px 0 0 30px;
        &:after {
          width: 20px;
          height: 20px;
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          border-radius: 4px;
          background: rgba(255, 255, 255, 0.08);
          border: 1px solid rgba(255, 255, 255, 0.12);
        }
      }
      input[type="checkbox"]:checked+label:after{
        background:url('../../../src/assets/images/checked.png') no-repeat -1px -1px;
      }
    }
    .social-icon{
      button{
        width: 50%;
        background: rgba(255, 255, 255, 0.08);
        border: 1.5px solid rgba(255, 255, 255, 0.12);
        border-radius: 10px;
        padding: 0;
        span{
          padding: 18px 20px;
          font-weight: 450;
          font-size: 16px;
          line-height: 20px;
          color: #828C9F;
          img{
            width: auto;
            margin-right: 10px;
          }
        }
        &:last-child{
          margin-left: 30px;
        }
      }
    }
    .or{
      &.buttons-holder{
        max-width: 27px;
        margin: 0 auto;
        padding: 40px 0;
      }
      &:before {
        width: 40%;
        height: 2px;
        position: absolute;
        content: "";
        left: -18px;
        top: 50%;
        margin-top: -1px;
        background: #828C9F;
        border-radius: 100px;
      }
      &:after {
        width: 40%;
        height: 2px;
        position: absolute;
        content: "";
        left: 37px;
        top: 50%;
        margin-top: -1px;
        background: #828C9F;
        border-radius: 100px;
      }
    }
    .right-content{
      display: flex;
      flex-direction: column;
      height: calc(100% - 50px);
      justify-content: center;
    }
  }
  .btn-login{
    background: linear-gradient(180deg, #00FFFF 0%, #2E3192 96.67%);
    border-radius: 10px;
    width: 100%;
    height: 60px;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    &.sign-up{
      width: max-content;
      margin-left: auto;
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0 20px;
    }
  }
  .success-text-color{
    color: green;
  }
  .error-text-color{
    color: red;
  }
}

@media (max-width: 1500px) {
  .login-area{
    .right-col{
      .right-content{
        display: inherit !important;
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-y: scroll;
        padding-top: 40px;
        &::-webkit-scrollbar{
          display: none;
        }
      }
    }
  }
}