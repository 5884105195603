@import "./../../../assets/scss/abstracts/mixins/media-quries.scss";
.about-us-banner {
    padding: 45px 0;
    @include breakpoint(tablet, max) {
        padding: 0;
        margin: 0;
    }
    &-hold {
        max-width: 950px;
        margin: 0 auto;
        h1 {
            .headin-img {
                width: clamp(275px, 50%, 440px);
                margin: 0 10px;
            }
        }
        .heading-head  {
            max-width: 780px;
            margin: 0 auto;
            line-height: 1.6;
            .h3 {
                margin: 0 0 18px;
            }
        }
    }
}