@import "./../../../assets//scss//abstracts/mixins/media-quries.scss";
.partners {
    padding: 45px 0;
    .heading-hold {
        max-width: 780px;
        margin: 0 auto 40px;
        line-height: 1.6;
    }
    .image-container {
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(250px, 1fr));
        gap: 20px;
        padding: 40px 0;
        @include breakpoint(widescreen, min) {
            grid-template-columns: repeat(4, 270px);
        }
        .image-hold {
            border: 1px solid rgba(255, 255, 255, 0.12);
            border-radius: 14px;
            min-height: 125px;
            .image-wrap {
                max-width: 183px;
                max-height: 53px;
            }
        }
    }
}