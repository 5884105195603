@import "./../../assets/scss/abstracts/mixins/media-quries.scss";
#footer {
    padding: 30px 0;
    .top-footer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 
        "foot_logo foot_logo"
        "lef_nav right_nav"
        "left_nav right_nav"
        ;
        padding: 40px 0;
        margin: 0 0 25px;
        border-bottom: 1px solid var(--footer_bottom_line_color);
        @include breakpoint(tablet, min) {
            grid-template-columns: 1fr 130px 1fr;
            grid-template-areas: "left_nav foot_logo right_nav";
        }
        @include breakpoint(desktop, min) {
            grid-template-columns: 1fr 155px 1fr;
        }
        .left-nav {
            grid-area: left_nav;
        }
        .right-nav {
            grid-area: right_nav;
        }
        .footer-logo {
            grid-area: foot_logo;
        }
        .footer-nav {
            display: grid;
            align-items: center;
            @include breakpoint(tablet, min) {
                display: flex;
            }
            &:last-child {
                justify-content: flex-end;
            }
            li {
                margin: 0;
                @include breakpoint(tablet, min) {
                    margin: 0 15px;
                }
                @include breakpoint(desktop, min) {
                    margin: 0 24px;
                }
            }
        }
    }
    .bottom-footer {
        padding: 30px 0;
        .social-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 40px;
            font-size: 16px;
            li {
                margin: 0 7px;
                .icon-hold {
                    --icon_hold_width_height: 45px;
                    width: var(--icon_hold_width_height);
                    height: var(--icon_hold_width_height);
                    border-radius: 50%;
                    background-color: var(--icon_bg_color);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}