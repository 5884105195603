@import "./../../../assets/scss/abstracts/mixins/media-quries.scss";
.slider-section {
    padding: 45px 0;
    .heading-head {
        margin: 0 0 25px;
        padding: 0 15px;
        display: grid;
        grid-template-columns: 1fr;
        @include breakpoint(tablet, min) {
            grid-template-columns: 590px 1fr;
            margin: 0 0 55px;
        }
        @include breakpoint(desktop, min) {
            grid-template-columns: 810px 1fr;
            gap: 0 10px;
        }
        @include breakpoint(desktop_max, min) {
            grid-template-columns: 860px 1fr;
            gap: 0 10px;
        }
        .slider-icon {
            display: none;
            align-items: flex-end;
            justify-content: flex-end;
            gap: 0 16px;
            @include breakpoint(tablet, min) {
                display: flex;
            }
            .left-arrow {
                transform: rotate(-180deg);
            }
            .icon-hold {
                --right_arrow_width_height: 48px;
                width: var(--right_arrow_width_height);
                height: var(--right_arrow_width_height);
                cursor: pointer;
            }
            svg {
                fill: var(--anchor_color);
                width: 100%;
                height: 100%;
                &:hover {
                    fill: var(--anchor_hover_color);
                }
            }
        }
    }
    .slick-list {
        padding: 0 60px 0 0 !important;
    }
    .slick-track {
        display: flex;
        gap: 0 20px;
    }
    .card {
        gap: 65px 15px;
        padding: 40px 0 0;
        @include breakpoint(tablet, min) {
            padding: 40px 0;
        }
        .slick-arrow {
            display: none !important;
        }
        .card-hold {
            .card-image {
                border-radius: 8px;
                overflow: hidden;
                margin: 0 0 30px;
                img {
                    height: 100%;
                }
            }
            .card-text {
                font-size: 15px;
                line-height: 1.6;
                display: grid;
                align-items: stretch;
                min-height: 180px;
                h4 {
                    margin: 0 0 16px;
                }
                p {
                    margin: 0 0 16px;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                }
                .card-icon {
                    display: flex;
                    align-items: flex-end;
                    .icon-hold {
                        --right_arrow_width_height: 22px;
                        width: var(--right_arrow_width_height);
                        height: var(--right_arrow_width_height);
                        margin: 0 10px 0 0;
                    }
                    svg {
                        fill: var(--anchor_color);
                    }
                    &:hover {
                        svg {
                            fill: var(--anchor_hover_color);
                        }
                    }
                }
            }
        }
    }
}