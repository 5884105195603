@import './../../assets/scss/abstracts/mixins/media-quries.scss';
@import './../../assets/scss/abstracts/mixins/reset-mixin.scss';
#header {
    .header-hold {
        @include display(grid);
        grid-template-columns: var(--logo_mobile) 1fr;
        padding: 25px 0 30px;
        align-items: center;
        @include breakpoint(min_phone, min){
            padding: 33px 0;
        }
        @include breakpoint(tablet, min){
            grid-template-columns: var(--logo_tablet) 1fr;
        }
        @include breakpoint(desktop, min){
            grid-template-columns: var(--logo_desktop) 1fr;
        }
        .logo {
            z-index: 2;
            @include breakpoint(tablet, min){
                z-index: 0;
            }
        }
        .hamb {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            z-index: 2;
            @include breakpoint(tablet, min){
                @include display(none);
                z-index: 0;
            }
            &-line {
                --same_width_height: 30px;
                @include display(block);
                position: relative;
                width: var(--same_width_height);
                height: var(--same_width_height);
                &:before, 
                &:after {
                    background: var(--cross_color);
                    content: '';
                    @include display(block);
                    height: 2px;
                    position: absolute;
                    transition: all .2s ease-out;
                    width: 20px;
                    margin: 0 auto;
                    left: 0;
                    right: 0;
                }
                &:before{
                    bottom: 30%;
                }
                &:after{
                    top: 30%;
                }
                @include breakpoint(tablet, max) {
                    --same_width_height: 24px;
                    &:before, 
                    &:after {
                        width: 12px;
                    }
                }
            }
        }
        .nav {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            right: -50px;
            max-width: 0;
            @include transition(opacity, 0.3s, ease, 0s);
            font-family: var(--font_family_circular_std);
            line-height: 1.2;
            background-color: var(--body_bg_color);
            background-image: url(./../../assets/images/mobile-nav-bg-image.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-attachment: fixed;
            @include breakpoint(tablet, min){
                background-image: none;
                background-color: transparent;
                position: static;
                inset: unset;
                max-width: initial;
                height: auto;
            }
            .menu {
                @include breakpoint(tablet, max) {
                    text-align: center;
                    height: 100%;
                    padding: 7rem 0 0;
                }
                @include breakpoint(tablet, min){
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
                li {
                    @include breakpoint(tablet, max) {
                        margin: 0 0 30px;
                        padding: 0 15px;
                        flex-direction: column;
                    }
                    &:not(:last-child) {
                        @include breakpoint(tablet, min) {
                            margin: 0 12px;
                        }
                        @include breakpoint(desktop, min) {
                            margin: 0 20px;
                        }
                    }
                    a {
                        --anchor_button_size: 18px;
                        @include breakpoint(tablet, min) {
                            --anchor_button_size: 16px;
                        }
                        @include breakpoint(desktop, min) {
                            --anchor_button_size: 18px;
                        }
                        &.active {
                            color: var(--anchor_hover_color);
                        }
                    }
                }
                .btn-group {
                    .btn {
                        @include breakpoint(tablet, max) {
                            min-width: 120px;
                        }
                        @include breakpoint(sm_phone, max) {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .side-menu {
            display: none;
            &:checked {
                & ~ nav {
                    max-width: 100%;
                    right: 0;
                    z-index: 1;
                }
                & ~ .hamb {
                    .hamb-line {
                        border: 2px solid var(--cross_color);
                        border-radius: 50%;
                        &:before, 
                        &:after {
                            top: 50%;
                            left: 50%;
                        }
                        &:before {
                            @include transform(translate(-50%, -50%) rotate(-45deg));
                        }
                        &:after {
                            @include transform(translate(-50%, -50%) rotate(45deg));
                        }
                    }
                }
            }
        }
    }
}