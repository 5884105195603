.smooth-scroll {
    .smooth-scroll-nav {
        margin: 0 0 38px;
        padding: 35px 0;
        li {
            margin: 0 20px;
        }
    }
    .image-hold {
        margin: 0 0 35px;
        border-radius: 18px;
        overflow: hidden;
    }
}