%border_box {
    box-sizing: border-box;
}
%margin_0 {
    margin: 0;
}
%padding_0 {
    padding: 0;
}
%display_block {
    display: block;
}