.single-feature-banner{
    padding: 45px 0;
    &-hold {
        display: grid;
        grid-template-columns: 270px 1fr;
        gap: 0 20px;
        .map-option {
            background-color: rgba(255, 255, 255, 0.08);
            border: 1.5px solid rgba(255, 255, 255, 0.12);
            border-radius: 18px;
            padding: 40px 24px;
            h3 {
                margin: 0;
            }
            &-hold {
                padding: 40px 0 0;
                .h3 {
                    margin: 0 0 32px;

                }
                li {
                    display: grid;
                    grid-template-columns: 15px 1fr;
                    align-items: center;
                    gap: 0 10px;
                    &:not(:first-child) {
                        padding-top: 10px;
                    }
                    &:not(:last-child) {
                        padding-bottom: 10px;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                    }
                    .text-hold {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }
        }
        .content {
            border-radius: 20px;
            overflow: hidden;
            img {
                aspect-ratio: 3/2;
                height: 100%;
            }
            .overlay {
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 1, 35, 0.25);
                padding: 0 40px;
                display: flex;
                align-items: flex-end;
                h2 {
                    margin: 0 0 70px;
                    position: relative;
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: -8px;
                        left: 0;
                        width: 100px;
                        height: 5px;
                        background-color: var(--bootom_line_after_color);
                    }
                }
            }
        }
    }
}