@import "./../../../assets/scss/abstracts/mixins/media-quries.scss";
.build-world {
    padding: 45px 0;
    margin: 0;
    @include breakpoint(tablet, min) {
        margin: 0 0 30px;
    }
    &-hold {
        --globe_img_width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        @include breakpoint(tablet, min) {
            --globe_img_width: 350px;
            grid-template-columns: var(--globe_img_width) 1fr;
            gap: 0 50px;
        }
        @include breakpoint(desktop, min) {
            --globe_img_width: 460px;
            gap: 0 70px;
        }
        .image-hold {
            width: var(--globe_img_width);
            display: flex;
            align-items: center;
            justify-content: center;
            @include breakpoint(tablet, max) {
                order: 2;
            }
        }
        .text-hold {
            padding: 0;
            line-height: 1.6;
            margin: 0 0 60px;
            @include breakpoint(widescreen, min) {
                padding: 0 0 0 80px;
            }
            @include breakpoint(tablet, min) {
                margin: 0;
            }
            p {
                strong {
                    margin: 0 8px 0 0;
                }
            }
            ul {
                padding: 40px 0 0;
                @include breakpoint(desktop, min) {
                    max-width: 530px;
                }
                li {
                    &:not(:last-child) {
                        margin: 0 0 24px;
                        @include breakpoint(tablet, min) {
                            margin: 0 0 10px;
                        }
                    }
                    .h4 {
                        margin: 0 0 8px;
                        @include breakpoint(tablet, min) {
                            margin: 0 0 4px;
                        }
                    }
                }
            }
        }
    }
}
.tooltip {
    padding: 6px 10px;
    background-color: var(--tooltip_bg_color);
    border: 1px solid #549BD7;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    font-size: 10px;
    @include breakpoint(sm_phone, min) {
        padding: 6px 15px;
        font-size: 11px;
    }
    cursor: pointer;
    strong {
        font-size: 11px;
        @include breakpoint(sm_phone, min) {
            font-size: 14px;
        }
        margin: 0 0 3px;
    }
}
.body-mind {
    top: 9%;
    right: -3%;
    @include breakpoint(sm_phone, min) {
        top: 16%;
    }
}
.cosmetic {
    top: 30%;
    right: 8%;
    @include breakpoint(sm_phone, min) {
        top: 35%;
        right: 14%;
    }
}
.fitness {
    bottom: 35%;
    left: 12%;
    @include breakpoint(sm_phone, min) {
        bottom: 40%;
        left: 15%;
    }
}