@import './../../assets/scss/abstracts/mixins/media-quries.scss';
.hero-banner {
    &-hold {
        h1 {
            .headin-img {
                width: clamp(275px, 50%, 440px);
                margin: 0 10px;
            }
        }
        .heading-head  {
            max-width: 754px;
            margin: 0 auto;
            line-height: 1.6;
            .h3 {
                margin: 0 0 25px;
                @include breakpoint(tablet, min) {
                    margin: 0 0 18px;
                }
            }
        }
    }
}