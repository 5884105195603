:root {
    --font_family_circular_std_book: 'Circular Std', Arial, Helvetica, sans-serif;
    --font_family_circular_std: 'Circular Std', Arial, Helvetica, sans-serif;
    --body_text_color: #fff;
    --body_bg_color: #000123;
    --logo_mobile: 180px;
    --logo_tablet: 210px;
    --logo_desktop: 270px;
    --anchor_color: #fff;
    --anchor_hover_color: #00FFFF;
    --cross_color: #00FFFF;
    --btn_color: #fff;
    --placeholder-color:#828C9F;
    --footer_bottom_line_color: rgba(255, 255, 255, 0.2);
    --icon_bg_color: #151645;
    --radio_btn_bg_color: #00FFFF, #000123;
    --radio_btn_checked: #fff;
    --bootom_line_after_color: #fff;
    --tooltip_bg_color: #00315CF2;
}